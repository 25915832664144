/* eslint-disable react/no-unknown-property */
import React, { ComponentProps } from "react";
import api from "api-web-client";
import App, { AppContext } from "next/app";
import { Nunito } from "next/font/google";
import cookie from "js-cookie";
import pick from "lodash/pick";

import { AppContainer } from "modules/App";
import { getDeviceID } from "modules/App/deviceId";
import { AppProviders } from "modules/App/AppProviders";
import { Catalog, getScreenCatalog } from "resources/AudiotekaApi";
import { getCatalogItems } from "utils/getCatalogItems";
import { isServer } from "utils/runtime";
import { getLocationInfo } from "utils/getLocationInfo";
import { localeToLang } from "utils/localeToLang";

import "../styles/normalize.scss";
import "../styles/global.scss";
import "../styles/scrollbar.scss";

const nunito = Nunito({
  subsets: ["latin-ext"],
  display: "swap",
  weight: ["300", "400", "500", "600", "700"],
  adjustFontFallback: false,
  fallback: ["Arial"], // Explanation: https://www.reddit.com/r/nextjs/comments/1bkxmd5/comment/kyooaob/
  preload: false,
});

const GlobalStyles = () => (
  <style jsx global>{`
    html {
      font-family: ${nunito.style.fontFamily};
      font-style: normal;
    }
  `}</style>
);

const apiUrl = new URL(`${isServer ? "/v2" : `/${cookie.get("store")}/v2`}`, process.env.API_URL);

api.initialize(apiUrl.toString(), {
  command: "RefreshTokenForWeb",
  payload: {
    device_id: getDeviceID(),
  },
});

function AudiotekaApp({ messages, ...props }: ComponentProps<typeof AppContainer> & { messages: any }) {
  return (
    <>
      <GlobalStyles />
      <AppProviders messages={messages}>
        <AppContainer {...props} />
      </AppProviders>
    </>
  );
}

function getAudiobookProps(catalog: Catalog) {
  const categoryList = catalog._embedded["app:screen-section"].find((section) => section.type === "category-list");
  const _categories = categoryList?._embedded["app:category"] || [];
  const categories = _categories.map((category) => pick(category, ["slug", "id", "name", "parent_id"]));

  return { categories };
}

AudiotekaApp.getInitialProps = async (appContext: AppContext) => {
  const {
    query: { locale = "pl" },
    pathname,
  } = appContext.ctx;

  const { catalogId } = getLocationInfo(locale as string);
  const catalog = await getScreenCatalog(catalogId).then((response) => response.data);

  const catalogItems = getCatalogItems(catalog);
  const messages = (await import(`../../public/static/locales/${localeToLang(locale as string)}/common.json`)).default;

  const isAudiobookPage = pathname === "/[locale]/audiobook/[id]";
  const audiobookProps = isAudiobookPage ? getAudiobookProps(catalog) : {};

  const appProps = await App.getInitialProps(appContext);

  return { ...appProps, catalogItems, messages, ...audiobookProps };
};

export default AudiotekaApp;
