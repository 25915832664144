import React from "react";
import { useTranslations } from "next-intl";

import { Product } from "resources/AudiotekaApi";
import { NewIcon } from "ui/NewIcon";

import { usePrice } from "./usePrice";
import { Rating } from "./rating";
import { Price } from "./price";
import { LowestPrice } from "./lowest-price";
import { ClubBadge } from "./club-badge";
import css from "./footer.module.scss";

export function Footer({ product, noPrice, colorless }: { product: Product; noPrice?: boolean; colorless?: boolean }) {
  const { isCycle } = usePrice(product, noPrice);
  const t = useTranslations("teaser");

  return (
    <>
      {isCycle && (
        <NewIcon
          accessibilityLabel={t("cycle")}
          role="img"
          tabIndex={0}
          icon="cycle"
          width="24"
          height="24"
          className={css.cycleIcon}
        />
      )}
      {!isCycle && <Rating rating={product.rating} />}
      <div className={css.priceWrapper}>
        <ClubBadge product={product} noPrice={noPrice} />
        <Price colorless={colorless} product={product} noPrice={noPrice} />
      </div>
      <LowestPrice product={product} noPrice={noPrice} />
    </>
  );
}
