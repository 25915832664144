import React from "react";
import { useTranslations } from "next-intl";

import { Product } from "resources/AudiotekaApi";
import { NewIcon } from "ui/NewIcon";
import { useUser } from "store/useUser";

import { usePrice } from "./usePrice";
import css from "./club-badge.module.scss";

interface Props {
  product: Product;
  noPrice?: boolean;
}

export function ClubBadge({ product, noPrice }: Props) {
  const user = useUser((state) => state.data);
  const { isFree, isAvailableInSubscription } = usePrice(product, noPrice);
  const t = useTranslations("teaser");

  const isBadgeVisible = isAvailableInSubscription || (isFree && user?.isClubMember);

  if (!isBadgeVisible) return null;

  return (
    <NewIcon
      accessibilityLabel={t("club_badge")}
      role="img"
      tabIndex={0}
      className={css.clubBadge}
      icon="club-circle-logo"
      width={18}
      height={18}
    />
  );
}
