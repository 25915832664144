import React from "react";
import classNames from "classnames";
import { useTranslations } from "next-intl";

import { Product } from "resources/AudiotekaApi";

import { usePrice } from "./usePrice";
import css from "./lowest-price.module.scss";

interface Props {
  colorless?: boolean;
  noPrice?: boolean;
  product: Product;
}

export function LowestPrice({ colorless, product, noPrice }: Props) {
  const { basePrice, isClubPrice, finalPrice, lowestPrice } = usePrice(product, noPrice);

  const t = useTranslations();

  const isLowestPrice = !isClubPrice && finalPrice && finalPrice !== basePrice && lowestPrice;

  if (!isLowestPrice) return null;

  return (
    <div className={classNames(css.lowestPrice, { [css.colorless]: colorless })}>
      {t("product.lowest_price", { price: lowestPrice.replace(/ /g, "\xa0") })}
    </div>
  );
}
