import React from "react";
import classNames from "classnames";

import { Product } from "resources/AudiotekaApi";

import { usePrice } from "./usePrice";
import css from "./price.module.scss";

interface Props {
  colorless?: boolean;
  noPrice?: boolean;
  product: Product;
}

export function Price({ colorless, product, noPrice }: Props) {
  const { finalPrice, basePrice, isClubPrice } = usePrice(product, noPrice);

  if (!finalPrice) return null;

  return (
    <p
      className={classNames(css.price, { [css.price__colorless]: colorless })}
      data-base-price={!isClubPrice && basePrice !== finalPrice ? basePrice : undefined}
    >
      {finalPrice}
    </p>
  );
}
