export const pathnames = {
  "/audiobook/[id]": {
    pl: "/audiobook/[id]",
    cz: "/audiokniha/[id]",
    sk: "/audiokniha/[id]",
    de: "/audiobook/[id]",
    lt: "/audiobook/[id]",
    es: "/audiobook/[id]",
  },
  "/author/[id]": {
    pl: "/autor/[id]",
    cz: "/autor/[id]",
    sk: "/autor/[id]",
    de: "/author/[id]",
    lt: "/author/[id]",
    es: "/author/[id]",
  },
  "/cart": {
    pl: "/koszyk",
    cz: "/kosik",
    sk: "/kosik",
    de: "/cart",
    lt: "/cart",
    es: "/cart",
  },
  "/catalog": {
    pl: "/katalog",
    cz: "/katalog",
    sk: "/katalog",
    de: "/catalog",
    lt: "/catalog",
    es: "/catalog",
  },
  "/catalog/[id]": {
    pl: "/katalog/[id]",
    cz: "/katalog/[id]",
    sk: "/katalog/[id]",
    de: "/catalog/[id]",
    lt: "/catalog/[id]",
    es: "/catalog/[id]",
  },
  "/copyright-notice": {
    pl: "/zastrzezenie-prawno-autorskie",
    cz: "/copyright-notice",
    sk: "/copyright-notice",
    de: "/copyright-notice",
    lt: "/copyright-notice",
    es: "/copyright-notice",
  },
  "/cycle/[id]": {
    pl: "/cykl/[id]",
    cz: "/serie/[id]",
    sk: "/seria/[id]",
    de: "/cycle/[id]",
    lt: "/cycle/[id]",
    es: "/cycle/[id]",
  },
  "/favourites": {
    pl: "/ulubione",
    cz: "/oblibene",
    sk: "/oblubene",
    de: "/favourites",
    lt: "/favourites",
    es: "/favourites",
  },
  "/help-center": {
    pl: "/centrum-pomocy",
    cz: "/kontakt-napoveda",
    sk: "/kontakt-pomocnik",
    de: "/help-center",
    lt: "/help-center",
    es: "/help-center",
  },
  "/help-center/[categoryId]": {
    pl: "/centrum-pomocy/[categoryId]",
    cz: "/kontakt-napoveda/[categoryId]",
    sk: "/kontakt-pomocnik/[categoryId]",
    de: "/help-center/[categoryId]",
    lt: "/help-center/[categoryId]",
    es: "/help-center/[categoryId]",
  },
  "/help-center/[categoryId]/[questionId]": {
    pl: "/centrum-pomocy/[categoryId]/[questionId]",
    cz: "/kontakt-napoveda/[categoryId]/[questionId]",
    sk: "/kontakt-pomocnik/[categoryId]/[questionId]",
    de: "/help-center/[categoryId]/[questionId]",
    lt: "/help-center/[categoryId]/[questionId]",
    es: "/help-center/[categoryId]/[questionId]",
  },
  "/join-audioteka-club": {
    pl: "/dolacz-do-audioteka-klub",
    cz: "/join-audioteka-club",
    sk: "/join-audioteka-club",
    de: "/join-audioteka-club",
    lt: "/join-audioteka-club",
    es: "/join-audioteka-club",
  },
  "/lector/[id]": {
    pl: "/lektor/[id]",
    cz: "/interpret/[id]",
    sk: "/interpret/[id]",
    de: "/lector/[id]",
    lt: "/lector/[id]",
    es: "/lector/[id]",
  },
  "/password-reset": {
    pl: "/reset-hasla",
    cz: "/obnoveni-hesla",
    sk: "/obnovenie-hesla",
    de: "/password-reset",
    lt: "/password-reset",
    es: "/password-reset",
  },
  "/podcast/[id]": {
    pl: "/podcast/[id]",
    cz: "/podcast/[id]",
    sk: "/podcast/[id]",
    de: "/podcast/[id]",
    lt: "/podcast/[id]",
    es: "/podcast/[id]",
  },
  "/publisher/[id]": {
    pl: "/wydawca/[id]",
    cz: "/vydavatel/[id]",
    sk: "/vydavatel/[id]",
    de: "/publisher/[id]",
    lt: "/publisher/[id]",
    es: "/publisher/[id]",
  },
  "/privacy-policy": {
    pl: "/polityka-prywatnosci",
    cz: "/zasady-ochrany-osobnich-udaju",
    sk: "/zasady-ochrany-osobnych-udajov",
    de: "/privacy-policy",
    lt: "/privacy-policy",
    es: "/privacy-policy",
  },
  "/recently-played": {
    pl: "/ostatnio-sluchane",
    cz: "/nedavno-prehravane",
    sk: "/nedavno-prehravane",
    de: "/recently-played",
    lt: "/recently-played",
    es: "/recently-played",
  },
  "/review-terms": {
    pl: "/zasady-opiniowania",
    cz: "/zasady-hodnoceni",
    sk: "/zasady-hodnotenia",
    de: "/review-terms",
    lt: "/review-terms",
    es: "/review-terms",
  },
  "/search": {
    pl: "/szukaj",
    cz: "/vyhledavani",
    sk: "/vyhladavanie",
    de: "/search",
    lt: "/search",
    es: "/search",
  },
  "/search/[phrase]": {
    pl: "/szukaj/[phrase]",
    cz: "/vyhledavani/[phrase]",
    sk: "/vyhladavanie/[phrase]",
    de: "/search/[phrase]",
    lt: "/search/[phrase]",
    es: "/search/[phrase]",
  },
  "/shelf": {
    pl: "/polka",
    cz: "/policka",
    sk: "/policka",
    de: "/shelf",
    lt: "/shelf",
    es: "/shelf",
  },
  "/sign-in": {
    pl: "/zaloguj",
    cz: "/prihlaseni",
    sk: "/prihlasenie",
    de: "/sign-in",
    lt: "/sign-in",
    es: "/sign-in",
  },
  "/sign-up": {
    pl: "/zarejestruj",
    cz: "/registrace",
    sk: "/registracia",
    de: "/sign-up",
    lt: "/sign-up",
    es: "/sign-up",
  },
  "/user-panel": {
    pl: "/panel-uzytkownika",
    cz: "/nastaveni-uctu",
    sk: "/nastavenie-uctu",
    de: "/user-panel",
    lt: "/user-panel",
    es: "/user-panel",
  },
  "/user-panel/consents": {
    pl: "/panel-uzytkownika/zgody",
    cz: "/nastaveni-uctu/souhlasy",
    sk: "/nastavenie-uctu/suhlasy",
    de: "/user-panel/consents",
    lt: "/user-panel/consents",
    es: "/user-panel/consents",
  },
  "/user-panel/family-sharing": {
    pl: "/panel-uzytkownika/podziel-sie",
    cz: "/nastaveni-uctu/sdileni",
    sk: "/nastavenie-uctu/zdielanie",
    de: "/user-panel/family-sharing",
    lt: "/user-panel/family-sharing",
    es: "/user-panel/family-sharing",
  },
  "/user-panel/profile": {
    pl: "/panel-uzytkownika/profil",
    cz: "/nastaveni-uctu/profil",
    sk: "/nastavenie-uctu/profil",
    de: "/user-panel/profile",
    lt: "/user-panel/profile",
    es: "/user-panel/profile",
  },
  "/user-panel/sessions": {
    pl: "/panel-uzytkownika/sesje",
    cz: "/nastaveni-uctu/relace",
    sk: "/nastavenie-uctu/relacie",
    de: "/user-panel/sessions",
    lt: "/user-panel/sessions",
    es: "/user-panel/sessions",
  },
  "/user-panel/lottery": {
    pl: "/panel-uzytkownika/loteria",
    cz: "/nastaveni-uctu/lottery",
    sk: "/nastavenie-uctu/lottery",
    de: "/user-panel/lottery",
    lt: "/user-panel/lottery",
    es: "/user-panel/lottery",
  },
  "/user-panel/manage-plan": {
    pl: "/panel-uzytkownika/zarzadzaj-klubem",
    cz: "/nastaveni-uctu/klub",
    sk: "/nastavenie-uctu/klub",
    de: "/user-panel/club",
    lt: "/user-panel/club",
    es: "/user-panel/club",
  },
} as const;

export const pathnamesKey = {
  audiobook: "/audiobook/[id]",
  author: "/author/[id]",
  applications: "/applications",
  cart: "/cart",
  catalog: "/catalog",
  category: "/catalog/[id]",
  copyrightNotice: "/copyright-notice",
  cycle: "/cycle/[id]",
  favourites: "/favourites",
  helpCenter: "/help-center",
  helpCenterWithCategory: "/help-center/[categoryId]",
  helpCenterWithQuestion: "/help-center/[categoryId]/[questionId]",
  home: "/",
  joinAudiotekaClub: "/join-audioteka-club",
  lector: "/lector/[id]",
  passwordReset: "/password-reset",
  player: "/player/[id]",
  podcast: "/podcast/[id]",
  privacyPolicy: "/privacy-policy",
  publisher: "/publisher/[id]",
  recentlyPlayed: "/recently-played",
  reviewTerms: "/review-terms",
  search: "/search/[phrase]",
  shelf: "/shelf",
  signIn: "/sign-in",
  signUp: "/sign-up",
  userPanel: "/user-panel",
  userPanel_consents: "/user-panel/consents",
  userPanel_familySharing: "/user-panel/family-sharing",
  userPanel_profile: "/user-panel/profile",
  userPanel_sessions: "/user-panel/sessions",
  userPanel_lottery: "/user-panel/lottery",
  userPanel_managePlan: "/user-panel/manage-plan",
} as const;

export type Pathname = keyof typeof pathnamesKey;

export function getPathname(name: Pathname, locale?: string) {
  const pathname = pathnamesKey[name];

  return `/[locale]${pathnames[pathname]?.[locale!] || pathname}`;
}
