import React, { useEffect } from "react";
import classNames from "classnames";

import { Link } from "ui/link";
import { deeplinkTranslator } from "modules/App/deeplinkTranslator";
import { useTeaserTracking } from "modules/teaser-tracking";
import { Product } from "resources/AudiotekaApi";

import { Cover } from "./cover/cover";
import { Footer } from "./footer/footer";
import css from "./teaser.module.scss";

interface Props {
  action?: React.ComponentProps<typeof Cover>["action"];
  colorless?: boolean;
  lazyLoading?: boolean;
  noPrice?: boolean;
  onClick?: (product: Product) => void;
  product: Product;
  progress?: number;
  isStrictLink?: boolean;
}

export function Teaser({
  action = "play",
  colorless,
  lazyLoading,
  noPrice,
  onClick,
  product,
  progress,
  isStrictLink = true,
}: Props) {
  const teaserTracking = useTeaserTracking();

  const { deeplink: link, description: author, name: title, slug } = product;
  const linkProps = deeplinkTranslator(link, slug);

  const isPlayable = linkProps.route === "audiobook";

  useEffect(() => {
    teaserTracking.addTeaser(product.id, product);

    return () => {
      teaserTracking.removeTeaser(product.id);
    };
  }, [product, teaserTracking]);

  const strictParams: Record<string, string> = isStrictLink ? {} : { "not-strict": "true" };

  return (
    <div
      className={classNames("adtk-item", css.teaser, { [css.teaser__colorless]: colorless })}
      data-item-id={product.id}
    >
      <Link
        className={css.mainLink}
        onClick={() => onClick?.(product)}
        title={product.name}
        route={linkProps.route!}
        params={{ ...linkProps.params, ...strictParams }}
      />
      <Cover action={action} isPlayable={isPlayable} lazyLoading={lazyLoading} product={product} progress={progress} />
      <p className={css.title}>{title}</p>
      {author && <p className={css.author}>{author}</p>}
      <Footer product={product} noPrice={noPrice} colorless={colorless} />
    </div>
  );
}
