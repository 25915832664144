import { useUser } from "store/useUser";
import { Product } from "resources/AudiotekaApi";

export const usePrice = (product: Product, noPrice?: boolean) => {
  const user = useUser((state) => state.data);

  const {
    deeplink: link,
    is_available_in_subscription: isAvailableInSubscription,
    price_for_subscribers: clubPrice,
    price: basePrice,
    is_free: isFree,
    discount_price: promoPrice,
    lowest_price: lowestPrice,
  } = product;

  const userHasClubAccess = isAvailableInSubscription && user?.isClubMember;
  const shouldShowPrice = basePrice && !noPrice && !isFree && !userHasClubAccess;
  const isClubPrice = user?.isClubMember && !!clubPrice;
  const clubPriceOrPromo = isClubPrice ? clubPrice : promoPrice;

  let finalPrice: string | null = null;

  if (shouldShowPrice) {
    finalPrice = clubPriceOrPromo ?? basePrice;
  }

  return {
    isCycle: /\/cycle\//.test(link),
    isAvailableInSubscription,
    isFree,
    basePrice,
    isClubPrice,
    finalPrice,
    lowestPrice,
  };
};
