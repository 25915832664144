import React from "react";
import { useTranslations } from "next-intl";

import { NewIcon } from "ui/NewIcon";

import css from "./rating.module.scss";

export function Rating({ rating }: { rating: number | null }) {
  const t = useTranslations("teaser");

  if (!rating || rating < 1) return null;

  return (
    <div className={css.rating}>
      <NewIcon
        accessibilityLabel={t("rating", { rating })}
        role="img"
        tabIndex={0}
        icon="star"
        width="13"
        height="13"
      />
      {Math.floor(rating * 10) / 10}
    </div>
  );
}
