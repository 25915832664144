import React, { ComponentProps } from "react";

import type { Product } from "resources/AudiotekaApi";

import { ActionButton } from "./action-button";
import css from "./cover.module.scss";

interface Props {
  action: ComponentProps<typeof ActionButton>["action"];
  isPlayable: boolean;
  lazyLoading?: boolean;
  product: Product;
  progress?: number;
}

export function Cover({ action, isPlayable, lazyLoading, product, progress }: Props) {
  return (
    <div className={css.cover}>
      <img
        className={css.image}
        loading={lazyLoading ? "lazy" : undefined}
        src={`${product.image_url}?w=360&auto=format`}
        alt={product.name}
      />
      <ActionButton className={css.actionButton} action={action} isPlayable={isPlayable} slug={product.slug} />
      {!!progress && progress > 0 && (
        <div className={css.progressBar} style={{ "--progress": `${progress}%` } as React.CSSProperties} />
      )}
    </div>
  );
}
